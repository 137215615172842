@import "./_appwork/include";
@import "./_theme/common";
@import "./_theme/libs";
@import "./_theme/uikit";
@import "./_appwork/variables";

$primary-color: $cor-padrao;

$body-bg: #f5f5f5;

body {
  background: $body-bg;
}

.bg-body {
  background: $body-bg !important;
}

@include appwork-common-theme($cor-padrao);
@include appwork-libs-theme($cor-padrao);
@include appwork-uikit-theme($cor-padrao);

// Navbar

@include appwork-navbar-variant('.bg-navbar-theme', #fff, $color: $text-muted, $active-color: $body-color);

.layout-navbar {
  box-shadow: 0 1px 0 $border-color;
}

// Sidenav

@include appwork-sidenav-variant('.bg-sidenav-theme', $cor-padrao , $color: #fff);

// Footer

@include appwork-footer-variant('.bg-footer-theme', #fff, $color: $text-muted, $active-color: $body-color);
